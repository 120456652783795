<template>
  <Mymodal
    :show="show"
    @close="close"
    @sure="close"
  >
    <div class="title ">活动规则</div>
    <div class="content">
      <div class="content">
        <div class="con">1. 每天登录签到可以领一定数量的盒子，也可以通过购买礼包获得一定数量的盒子。</div>
        <div class="con">2. 消耗一定数量盒子获得掷骰子机会，根据骰子点数走格子，在对应各自上获取相应的兑换币，且能获得途中经过的所有兑换币。</div>
        <div class="con">3. 掷骰子玩法一共有5个关卡，每个关卡有24个格子，当前关卡走至终点后，从下一关起点开始。</div>
      </div>
    </div>

  </Mymodal>
</template>
<script>
  import { mapState } from "vuex"
  export default {
    name: '',
    computed:{
      ...mapState({
        show:(state)=>state.dialog === 'rule'
      })
    },
    data(){
      return {
      }
    },
    methods:{
      close(){
        this.$store.commit('SETVAL',{dialog:''})
      }
    },
    mounted(){
    },
  }
</script>
<style scoped lang='less'>
  .tit,.con {
    font-size: 24px;
    padding: 0 75px;
    line-height: 40px;
  }
  .tit {
    color: #D1401E;
  }
  .con {
    color:#333;
  }
  .m20 {
    margin-bottom:20px;
  }
</style>